<template>
  <ul
    class="flex flex-row flex-wrap p-4 md:px-0  border-gray border-t lg:border-0"
  >
    <li
      v-for="(link, index) in links"
      :key="index"
      class="pe-4 mb-2 md:mb-0 last:pe-0"
    >
      <ui-link
        v-if="link"
        class="text-xs sm:text-base"
        color="gray-black"
        :url="link.url || link.path"
        :title="link.title"
        :no-follow="link.noFollow"
        :blank="link.newTab"
      >
        {{ link.title }}
      </ui-link>
    </li>
  </ul>
</template>

<script>
import VueTypes from 'vue-types'
import { UiLink } from '~/components/atoms'

export default {
  components: {
    UiLink,
  },
  props: {
    links: VueTypes.array.def([]),
  },
}
</script>
